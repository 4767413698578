import React, { Component } from "react";
import { Fragment } from "react";
import Dashboard from "./Components/Dashboard";
import { BrowserRouter as Switch, Route } from "react-router-dom";
import AwesomeComponent from './Components/Loading';

export default class extends Component {
  
  constructor(){
    super();
    this.state = {
      moderator: false,
      userLocation: ""
    }
  }

  componentDidMount(){
    this.getLocation()
    this.isAppAdmin()
  }

  isAppAdmin(){
    this.props.stitchAuthedClient
    .callFunction("isAppAdmin")
    .then(
      x => {
        // console.log("moderator is", x)
        this.setState({moderator:x})
      })
  }

  getLocation() { 
    const stitchAuthedClient = this.props.stitchAuthedClient;

    stitchAuthedClient
    .callFunction("testfunction",[])
    .then(x => {
      if(x.status === 200){
        // console.log(x.body.location)
        this.setState({userLocation: x.body.location})
        // console.log("userLocation is ", this.state.userLocation)
      } 
    })
  }

  render() {
    if (this.state.userLocation===""){
      return <div><AwesomeComponent/></div>
    } 
    return (
      <Fragment>
        <Switch>
        {/*<Route
            path="/voting"
            render={props => (
              <Dashboard {...props} moderator={this.state.moderator} stitchAuthedClient={this.props.stitchAuthedClient} user={this.props.user} url={"/voting"} />
            )}
            />*/}
          <Route
            path="/lastallhands"
            render={props => (
              <Dashboard {...props} moderator={this.state.moderator} stitchAuthedClient={this.props.stitchAuthedClient} user={this.props.user} url={"/lastallhands"} />
            )}
          />
          <Route
            path="/reports"
            render={props => (
              <Dashboard {...props} moderator={this.state.moderator} stitchAuthedClient={this.props.stitchAuthedClient} user={this.props.user} url={"/reports"} />
            )}
          />
          <Route
            path="/createevent"
            render={props => (
              <Dashboard {...props} moderator={this.state.moderator} stitchAuthedClient={this.props.stitchAuthedClient} user={this.props.user} url={"/createevent"} />
            )}
          />
          <Route
            path="/execresponse"
            render={props => (
              <Dashboard {...props} moderator={this.state.moderator} stitchAuthedClient={this.props.stitchAuthedClient} user={this.props.user} url={"/execresponse"} />
            )}
          />
          <Route
            path="/searchstuff"
            render={props => (
              <Dashboard {...props} moderator={this.state.moderator} stitchAuthedClient={this.props.stitchAuthedClient} user={this.props.user} url={"/searchstuff"} />
            )}
          />
          <Route
            exact path="/"
            render={props => <Dashboard {...props} moderator={this.state.moderator} stitchAuthedClient={this.props.stitchAuthedClient} user={this.props.user} userLocation={this.state.userLocation} url={"/"} />}
          />
        </Switch>
      </Fragment>
    );
  }
}

import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Container from "@material-ui/core/Container";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  root: {
    width: "100%",
    padding: "1em"
  },
  text: {
    width: "100%",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  button: {
    boxShadow: "none",
    textTransform: "none",
    fontSize: 16,
    padding: "6px 12px",
    border: "1px solid",
    lineHeight: 1.5,
    backgroundColor: "rgb(18, 146, 79)",
    borderColor: "rgb(18, 146, 79)",
    borderRadius: 5,
    cursor: "pointer"
  }
});

class QuestionFormClosedEvent extends Component {
  constructor() {
    super();
    this.state = {
      question: "",
      username: "",
      isAnonymous: false,
      msg: "",
      anon: "Names will be captured with questions",
      finalQuestion: ""
    };
    this.postToStitch = this.postToStitch.bind(this);
  }

  handleQuestionChange = event => {
    this.setState({ question: event.target.value });
  };

  handleIsAnonymousChange = event => {
    this.setState({ isAnonymous: event.target.checked });
    if (event.target.checked) {
      this.setState({ anon: "I wish to remain anonymous!" });
    }
    this.setState({ username: this.state.isAnonymous ? this.props.user : "" });
  };

  handleSubmit = event => {
    // event.PreventDefault();
    let question = this.state.question.trim();
    let isAnonymous = this.state.isAnonymous;
    let user = this.props.user; // changed from state to props to disable anonymity
    let data = { question: question, isAnonymous: isAnonymous, username: user, location: this.props.userLocation, event_id: this.props.event_id };
    this.postToStitch(data);
    this.setState({
      finalQuestion: "Submitted Question: " + question,
      question: "",
      username: null,
      // isAnonymous: true,
      msg: ""
    });
  };

  postToStitch = data => {
    // console.log("data in stitch is: ", data);
    const stitchAuthedClient = this.props.stitchAuthedClient;
    stitchAuthedClient
      .callFunction("submitQuestion", [JSON.stringify(data)])
      .then(x => {          
        if (x.status !== 200) {
          this.setState({
            msg:
              "There must be something on your mind. Think hard!",
            finalQuestion: ""
          });
        }
      });
  };

  render() {
    const { classes } = this.props;

    return (
        <Container class={classes.root}>
        <br />
        <b>
          <i> No changes can be made to question post submission.
          Please mention the name of the office if it is not a global question. </i>
        </b>
        <br />
        <TextField
          disabled
          fullWidth
          value={this.state.finalQuestion}
          rows="4"
        />
        <TextField
          id="input-question"
          label="What's on your mind?"
          multiline
          rows="4"
          margin="normal"
          fullWidth
          inputProps={{ maxLength: 1000 }}
          onChange={this.handleQuestionChange}
          value={this.state.question}
        />

        <b style={{color:"gray", "padding-right":"1em"}}><i>{this.state.anon}</i></b>

        <Button onClick={this.handleSubmit} variant="contained" color="primary" >
          SUBMIT QUESTION
        </Button>
        <br />
        <div><b style={{ color: "red" }}>{this.state.msg}</b></div>
      </Container>
    );
  }
}

QuestionFormClosedEvent.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(QuestionFormClosedEvent);

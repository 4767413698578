import React from 'react'
import App from './App';
import { BrowserRouter } from 'react-router-dom'
import ReactDOM from 'react-dom';
import Login from './Login'

ReactDOM.render((
    <BrowserRouter>
      <Login />
    </BrowserRouter>
  ), document.getElementById('root'))
  
// render(<App />, document.getElementById("root"))
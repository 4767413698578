import React, { Component } from "react";
import {
  fade,
} from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";

const iframe = '<iframe style="border: none;border-radius: 2px;box-shadow: 0 2px 10px 0 rgba(70, 76, 79, .2);" width="100%" height="480" src="https://charts.mongodb.com/charts-ankur-mw-prep-unxqc/embed/charts?id=1feed344-60e3-4e55-a17f-c6fdc58e8c4e&tenant=52c6447f-88fd-4b10-906d-0f7d5c8a4403"></iframe>';
function Iframe(props) {
    return (<div dangerouslySetInnerHTML={ {__html:  props.iframe?props.iframe:""}} />);
  }

const StyledTable = withStyles(theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto"
  },
  table: {
    minWidth: 700
  }
}))(Table);

const StyledPaper = withStyles(theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto"
  },
  table: {
    minWidth: 700
  }
}))(Paper);

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  body: {
    fontSize: 14
  }
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default
    }
  }
}))(TableRow);

const styles = theme => ({
  grow: {
    flexGrow: 1
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block"
    }
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25)
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto"
    }
  },
  searchIcon: {
    width: theme.spacing(7),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  inputRoot: {
    color: "inherit"
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: 200
    }
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex"
    }
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none"
    }
  },
  button: {
    boxShadow: "none",
    textTransform: "none",
    fontSize: 16,
    padding: "6px 12px",
    border: "1px solid",
    lineHeight: 1.5,
    backgroundColor: "rgb(18, 146, 79)",
    borderColor: "rgb(18, 146, 79)",
    borderRadius: 5,
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(","),
    "&:hover": {
      backgroundColor: "#0069d9",
      borderColor: "#0062cc"
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "#0062cc",
      borderColor: "#005cbf"
    },
    "&:focus": {
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)"
    }
  }
});

function createData(qid, question, who, response, responder, when, votes) {
  if (!(votes instanceof Array)) {
    votes = [];
  }
  const voteCount = votes.length;
  // check if responder is undefined (tackling current data issue)
  // In general, responder should be an array of document
  const name = typeof responder !== "undefined" ? responder[0]["name"] : responder;
  return { qid, question, who, response, name, when, voteCount };
}

class LastAllHands extends Component {
  constructor() {
    super();
    this.state = {
      question: "",
      rows: [],
      isEmpty: false,
      month: ""
    };
  }

  getEventFromStitch = data => {
    const stitchAuthedClient = this.props.stitchAuthedClient;
    // console.log("stitchAuthedClient ", stitchAuthedClient);
    stitchAuthedClient
      .callFunction("lastClosedEvent", [data])
      .then(x => {
        // console.log("Status is", x);
        return x;
      })
      .then(res => {
        // console.log("RESULT of event fetch: ", res);
        const parseRes = JSON.parse(res);
        const date = new Date(parseRes.date)
        const month = date.getMonth();

        const month_name = function(dt){
          const mlist = [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ];
            return mlist[dt];
          };

        this.setState({
          month: month_name(month) + " All Hands"
        });
        // console.log("HERE: ", this.state);
      });
  }

  getQuesFromStitch = data => {
    // console.log("data in stitch is: ", data);
    const stitchAuthedClient = this.props.stitchAuthedClient;
    // console.log("stitchAuthedClient ", stitchAuthedClient)
    stitchAuthedClient.callFunction("fetchLastAllHandsQuesFunction",[data])
      .then(resData => {
        this.setState({isEmpty:false})
        // console.log("resdata is ", resData);
        let resDataProcessed = [];
        JSON.parse(resData).forEach(x => {
          let res = createData(
            x.question_id,
            x.question,
            x.username,
            x.response,
            x.responder,
            x.posted_on,
            x.votes
          );
          resDataProcessed.push(res);
        });
        this.setState({ rows: resDataProcessed });
        if (resDataProcessed.length === 0) {
          this.setState({isEmpty:true})
        }
        // console.log("resdataprocessed ", resDataProcessed);
      });
  };

  componentWillMount() {
    this.getQuesFromStitch(JSON.stringify({}));
    this.getEventFromStitch(JSON.stringify({}));
  }
  
  render() {
    
    return (
        <StyledPaper>
        <div><h3>{this.state.month}</h3></div>
        {/*<Iframe iframe={iframe}/>*/}
          <StyledTable>
            <TableHead>
              <TableRow>
                <StyledTableCell>Q No.</StyledTableCell>
                <StyledTableCell>Question</StyledTableCell>
                <StyledTableCell align="right">Who Asked</StyledTableCell>
                <StyledTableCell align="right">Response</StyledTableCell>
                <StyledTableCell align="right">Responder</StyledTableCell>
                <StyledTableCell align="right">When</StyledTableCell>
                <StyledTableCell align="right">Votes</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.rows.map(row => (
                <StyledTableRow key={row.qid}>
                  <StyledTableCell component="th" scope="row">
                    {row.qid}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {row.question}
                  </StyledTableCell>
                  <StyledTableCell align="right">{row.who}</StyledTableCell>
                  <StyledTableCell align="right">
                    {row.response}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {row.name}
                  </StyledTableCell>
                  <StyledTableCell align="right">{row.when}</StyledTableCell>
                  <StyledTableCell align="right">
                    {row.voteCount}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </StyledTable>
        </StyledPaper>
    );
  }
}

LastAllHands.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(LastAllHands);

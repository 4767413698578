import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import AdminPanel from "./AdminPanel";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const styles = theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    maxWidth: "xl",
    fullWidth: true
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    fullWidth: true,
    display: "block"
  },
  fixedHeight: {
    height: 80
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    width: "100%",
    alignItems: "center"
  }
});

class CreateEvent extends Component {
  constructor() {
    super();
    this.state = {
      subject: "",
      details: "",
      time: "",
      oldDetails: "",
      oldDate: "",
      noevent: false,
      closeEventMsg: ""
    };
  }

  handleSubjectChange = event => {
    this.setState({ subject: event.target.value });
  };

  handleDetailsChange = event => {
    this.setState({ details: event.target.value });
  };

  handleDateChange = event => {
    this.setState({ time: event.target.value });
  };

  handleSubmit = event => {
    // event.PreventDefault();
    let subject = this.state.subject.trim();
    let details = this.state.details;
    let time = this.state.time;
    let data = { details: subject + "\n" + details, date: time };
    this.postToStitch(JSON.stringify(data));
    // console.log(data);
    this.setState({ subject: "", time: "", msg: "" });
  };

  postToStitch = data => {
    // console.log("data in stitch is: ", data);
    const stitchAuthedClient = this.props.stitchAuthedClient;
    // console.log("stitchAuthedClient ", stitchAuthedClient);
    stitchAuthedClient
      .callFunction("createOpenEventFunction", [data])
      .then(x => {
        // console.log("Status is", x);
        if (x.status === 200) {
          this.setState({ msg: "Submitted successfully!" });
        } else {
          this.setState({ msg: "Oops something went wrong. Try again." });
        }
      });
  };

  componentWillMount() {
    const stitchAuthedClient = this.props.stitchAuthedClient;
    // console.log("stitch authed client", this.props);
    this.getEventFromStitch(JSON.stringify({ status: "open" }));
  }

  getEventFromStitch = data => {
    // console.log("data in stitch is: ", data);
    // console.log("data in stitch is: ", data);
    const stitchAuthedClient = this.props.stitchAuthedClient;
    // console.log("stitchAuthedClient ", stitchAuthedClient);
    stitchAuthedClient
      .callFunction("fetchEventsFunction", [data])
      .then(x => {
        // console.log("Status is", x);
        return x;
      })
      .then(res => {
        // console.log("RESULT of event fetch: ", res, res[0]);
        const parseRes = JSON.parse(res);
        if (parseRes.length !== 0) {
          // console.log("Parse RESULT of event fetch: ", parseRes, parseRes[0]);
          this.setState({
            oldDate: parseRes[0].date,
            oldDetails: parseRes[0].details
          });
        } else {
          this.setState({ noevent: true });
        }
        // console.log("HERE: ", this.state);
      });
  };

  handleCloseEvent = () => {
    const stitchAuthedClient = this.props.stitchAuthedClient;
    // console.log("stitch authed client", stitchAuthedClient);
    stitchAuthedClient
      .callFunction("closeAllEventsFunction", [JSON.stringify("")])
      .then(x => {
        // console.log("Status is", x);
        if (x.status === 200) {
          this.setState({ closeEventMsg: "Closed Event! Refresh Page" });
        } else {
          this.setState({
            closeEventMsg: "Oops something went wrong. Try again."
          });
        }
      });
  };

  render() {
    const { classes } = this.props;
    const eventDate = new Date(Date.parse(this.state.oldDate));

    return (
      <Container className={classes.container}>
        {this.state.noevent && (
          <ExpansionPanel className={classes.paper}>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>Schedule All Hands</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails >
              <form noValidate>
                <TextField
                  id="subject"
                  label="Subject"
                  margin="normal"
                  fullWidth
                  inputProps={{ maxLength: 100 }}
                  onChange={this.handleSubjectChange}
                  className={classes.textField}
                  required
                />
                <TextField
                  id="datetime-local"
                  label="Schedule Date & Time"
                  type="datetime-local"
                  onChange={this.handleDateChange}
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true
                  }}
                  value={this.state.date}
                  required
                />
                <TextField
                  id="details"
                  label="Details"
                  multiline
                  rows="15"
                  margin="normal"
                  fullWidth
                  onChange={this.handleDetailsChange}
                  value={this.state.details}
                  className={classes.textField}
                  defaultValue=""
                  required
                />
                <Button
                  onClick={this.handleSubmit}
                  variant="contained"
                  color="primary"
                >
                  CREATE EVENT
              </Button>
                <br />
                <b>{this.state.msg}</b>
              </form>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        )}
        {!this.state.noevent && (
          <Paper className={classes.paper} alignItems="center">
            <b>Current Running All Hands on {eventDate.toString()}</b>
            <p style={{ "white-space": "pre-wrap" }}>{this.state.oldDetails}</p>
            <br />
            <Button
              onClick={this.handleCloseEvent}
              variant="contained"
              color="primary"
            >
              CLOSE EVENT
            </Button>
            <div>{this.state.closeEventMsg}</div>
            <br />
          </Paper>
        )}
        <AdminPanel user={this.props.user} stitchAuthedClient={this.props.stitchAuthedClient} userLocation={this.props.userLocation}/>
      </Container>
    );
  }
}

CreateEvent.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(CreateEvent);

import React, { Component } from 'react'

function NotAuthorised() {
    return (
        <div>
        You are not authorised to view this page.
        </div>
    )
}

export default NotAuthorised;
import React, { Component } from "react";
import { fade } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from "clsx";
import Grid from "@material-ui/core/Grid";
import YearMonthPicker from 'react-year-month-picker';
import Button from "@material-ui/core/Button";

const StyledTable = withStyles(theme => ({
    root: {
        width: 3000,
        marginTop: theme.spacing(3),
        overflowX: "auto"
    },
    table: {
        minWidth: 1000
    }
}))(Table);

const StyledPaper = withStyles(theme => ({
    root: {
        width: "100%",
        marginTop: theme.spacing(3),
        overflowX: "auto"
    },
    table: {
        minWidth: 700
    }
}))(Paper);

const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white
    },
    body: {
        fontSize: 16,
        size: "medium"
    }
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
    root: {
        "&:nth-of-type(odd)": {
            backgroundColor: theme.palette.background.default
        }
    }
}))(TableRow);

const styles = theme => ({
    smallHeight: {
        paddingTop: 10,
        paddingRight: 10,
        paddingBottom: 10,
        paddingLeft: 10
    },
    grow: {
        flexGrow: 1
    },
    menuButton: {
        marginRight: theme.spacing(2)
    },
    title: {
        display: "none",
        [theme.breakpoints.up("sm")]: {
            display: "block"
        }
    },
    search: {
        position: "relative",
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        "&:hover": {
            backgroundColor: fade(theme.palette.common.white, 0.25)
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: "100%",
        [theme.breakpoints.up("sm")]: {
            marginLeft: theme.spacing(3),
            width: "auto"
        }
    },
    searchIcon: {
        width: theme.spacing(7),
        height: "100%",
        position: "absolute",
        pointerEvents: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    inputRoot: {
        color: "inherit"
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 7),
        transition: theme.transitions.create("width"),
        width: "100%",
        [theme.breakpoints.up("md")]: {
            width: 200
        }
    }
});

function createData(qid, question, who, response, responder, when, votes, hidden) {
    if (!(votes instanceof Array)) {
        votes = [];
    }

    if (!(responder instanceof Array)) {
        responder = [{ name: "", title: "" }];
    }

    const voteCount = votes.length;
    const name = responder[0]["name"];
    // console.log(' responder[0]["name"]', responder[0]["name"]);
    return { qid, question, who, response, name, when, voteCount, hidden };
}

class Reports extends Component {
    constructor() {
        super();
        this.state = {
            question: "",
            rows: [],
            isEmpty: false,
            scheduled: null,
            msg: null
        };
    }

    handleQuestionChange = event => {
        this.setState({ question: event.target.value });
    };

    handleChange(m) {
        this.setState({ scheduled: m }, () => console.log(this.state.scheduled.month(), this.state.scheduled.year()))
    }

    handleSubmit = event => {
        // event.PreventDefault();
        if (this.state.scheduled != null) {
            this.getFromStitch(JSON.stringify({ "month": this.state.scheduled.month(), "year": this.state.scheduled.year() }));
        }
    };

    getFromStitch = data => {
        // console.log("data in stitch is: ", data);
        const stitchAuthedClient = this.props.stitchAuthedClient;
        // console.log("stitchAuthedClient ", stitchAuthedClient);
        stitchAuthedClient
            .callFunction("fetchQuestionsForMonth", [data])
            .then(x => {
                // console.log("Status is", x);
                // this.setState({rows:x})
                return x
            })
            .then(resData => {
                this.setState({ isEmpty: false });
                if (resData.status !== 422) {
                    let resDataProcessed = [];
                    if (JSON.parse(resData).length > 0) {
                        JSON.parse(resData).forEach(x => {
                            let res = createData(
                                x.question_id,
                                x.question,
                                x.username,
                                x.response,
                                x.responder,
                                x.posted_on,
                                x.votes,
                                x.hidden
                            );
                            resDataProcessed.push(res);
                        });
                        this.setState({ rows: resDataProcessed, msg: "" });
                    }
                    if (resDataProcessed.length === 0) {
                        this.setState({ isEmpty: true, msg: "" });
                    }
                } else {
                    this.setState({
                        msg:
                            "Event doesn't exist."
                    })
                }
                // console.log("resdataprocessed ", resDataProcessed);
            });
    };

    dateManipulator(d) {
        var dateObj = new Date(d);
        var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        return months[dateObj.getMonth()] + " " + dateObj.getFullYear()
    }

    render() {
        const { classes } = this.props;
        const smallHeightPaper = clsx(classes.paper, classes.smallHeight);

        return (
            <Container>
                <Grid item xs style={{ width: '100%' }}>
                    <Paper className={smallHeightPaper}>
                        <b>This page is still in beta. Select the month and year of the all hands meeting that you are looking for:</b>
                    </Paper>
                    <br />
                </Grid>

                <div>
                    <YearMonthPicker
                        defaultYear={2019}
                        defaultMonth={3}
                        minYear={2018}
                        maxYear={2020}
                        closeOnSelect
                        onChange={this.handleChange.bind(this)}
                    />
                </div>

                {this.state.msg}
                {this.state.isEmpty && "Nothing published in this event."}

                <br />
                <Button onClick={this.handleSubmit} variant="contained" color="primary" >
                    SUBMIT
        </Button>

                {this.state.rows.map(row => (
                    !row.hidden &&
                    <ExpansionPanel>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <div style={{ padding: "2px" }}>
                                <b>{row.question}</b>
                                &nbsp;
                <i>
                                    Posted On: {this.dateManipulator(row.when)}
                                    &nbsp;
              Posted By: {row.who}
                                    &nbsp;
              Responded By: {row.name}
                                    &nbsp;
              Votes: {row.voteCount}
                                </i>
                            </div>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <div style={{ color: "blue" }}><i>{row.response}</i></div>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                ))}

            </Container>
        );
    }
}

Reports.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Reports);

import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import ThumbUp from "@material-ui/icons/ThumbUp";
import Badge from "@material-ui/core/Badge";
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

const theme = createMuiTheme({
    palette: {
        primary: {
            light: '#bdbdbd',
            main: '#388e3c'
        },
        secondary: {
            light: '#bdbdbd',
            main: '#757575'
        },
        error: {
            light: '#bdbdbd',
            main: '#1a237e'
        }
    }
});

let global_username = "";

const StyledTable = withStyles(theme => ({
    root: {
        width: "100%",
        marginTop: theme.spacing(3),
        overflowX: "auto",
    },
    table: {
        minWidth: 700
    }
}))(Table);

const StyledPaper = withStyles(theme => ({
    root: {
        width: "100%",
        overflowX: "auto"
    },
    table: {
        minWidth: 700
    }
}))(Paper);

const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white
    },
    body: {
        fontSize: 16,
        fontFamily: "sans-serif",
        fontWeight: 600,
        overflow: "auto",
    }
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
    root: {
        "&:nth-of-type(odd)": {
            backgroundColor: theme.palette.background.default
        }
    }
}))(TableRow);

function createData(qid, question, who, votecount, voted, posted_on) {
    const voteCount = votecount;
    let color = (voted !== -1) ? "primary" : "secondary";
    return { qid, question, who, color, voteCount, posted_on };

}

export default class extends Component {
    //   const classes = useStyles();

    constructor() {
        super();
        this.state = {
            rows: [],
            noevent: false,
            refreshNow: false
        };
    }

    componentDidMount() {
        // console.log(this.props)
        this.getFromStitch(JSON.stringify({}));
    }

    componentDidUpdate(prevProps) {
        // console.log("cdu ",this.props.refresh, "prevProps", prevProps.refresh)
        if (this.props.refresh === "refresh" && this.props.refresh !== prevProps.refresh) {
            this.getFromStitch(JSON.stringify({}));
        }
    }

    getFromStitch = data => {
        const stitchAuthedClient = this.props.stitchAuthedClient;
        stitchAuthedClient
            .callFunction("lastClosedOpenForResponseLimited", [data])
            .then(x => {
                //console.log("Status is", x);
                // this.setState({rows:x})
                return x;
            })
            .then(resData => {
                if (resData.status !== 422) {
                     console.log("resdata from getfromstitch is ", resData);
                    global_username = this.props.user;
                    let resDataProcessed = [];
                    JSON.parse(resData).forEach(x => {
                        let res = createData(
                            x.question_id,
                            x.question,
                            x.username,
                            x.votescount,
                            x.voted,
                            x.posted_on,
                        );
                        resDataProcessed.push(res);
                    });
                    // Sort data by number of votes descending
                    resDataProcessed.sort((a, b) => b.voteCount - a.voteCount);
                    this.setState({ rows: resDataProcessed });
                     //console.log("resdataprocessed ", resDataProcessed);
                    // console.log("date is: ",resDataProcessed.posted_on)
                } else {
                    this.setState({ noevent: true })
                }
            });
    };

    render() {

        const { classes } = this.props;

        return (
            <ThemeProvider theme={theme}>
                <StyledPaper>
                    <p align="center">
                        <b>Here are the questions which were asked and voted on!</b>
                    </p>
                    <StyledTable>
                        <TableHead>
                            <TableRow>
                                <StyledTableCell style={{ "font-size": 16 }}><b>Question</b></StyledTableCell>
                                <StyledTableCell style={{ "font-size": 16 }} align="right"><b>Who Asked</b></StyledTableCell>
                                <StyledTableCell style={{ "font-size": 16 }} align="right"><b>Upvote</b></StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.rows.map(row => (
                                !row.hidden &&
                                <StyledTableRow key={row.qid}>
                                    <StyledTableCell component="th" scope="row" style={{
                                        whiteSpace: "pre-wrap",
                                        wordWrap: "break-word",
                                        maxWidth: "730px"
                                    }}>
                                        {row.question}
                                        <br />
                     <i style={{color:"#A29F9E", "font-size":"12px"}}>{"\nPosted On : "+ new Date(Date.parse(row.posted_on))}</i>
                                    </StyledTableCell>
                                    <StyledTableCell align="right">{row.who}</StyledTableCell>
                                    <StyledTableCell align="right">
                                        <Badge badgeContent={row.voteCount} color={row.color}>
                                            <ThumbUp />
                                        </Badge>
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </StyledTable>
                    {this.state.noevent && <p align="center"><b>Looks like there is no open event</b></p>}
                </StyledPaper>
            </ThemeProvider>
        );
    }
}


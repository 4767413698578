import React, { Component, Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import CsvDownload from "react-json-to-csv";
import Grid from "@material-ui/core/Grid";
import ThumbUp from "@material-ui/icons/ThumbUp";
import Badge from "@material-ui/core/Badge";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";

function createData(
  idx,
  qid,
  question,
  who,
  votes,
  status,
  response,
  responder,
  hidden
) {
  if (!(votes instanceof Array)) {
    votes = [];
  }
  // console.log("responder check: ", responder)
  if (!(responder instanceof Array)) {
    responder = [{ name: "", title: "", eid: "" }];
  }

  // console.log("responder check2: ", responder)
  if (!("eid" in responder[0])) {
    responder[0]["eid"] = "";
  }

  const execName = responder[0].name;
  const execTitle = responder[0].title;
  const execID = responder[0].eid;
  const voteCount = votes.length;
  const executive = {};
  return {
    idx,
    qid,
    question,
    who,
    voteCount,
    status,
    response,
    execName,
    execTitle,
    execID,
    hidden
  };
}

const styles = theme => ({
  root: {
    width: "100%",
    fontWeight: theme.typography.fontWeightBold,
    overflow: "auto"
  },
  text: {
    width: "100%",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  expansionPanel: {
    display: "block"
  },
  button: {
    backgroundColor: "#4CAF50" /* Green */,
    border: "none",
    color: "white",
    padding: "15px 32px",
    textAlign: "center",
    textDecoration: "none",
    display: "inline-block",
    fontSize: "16px",
    cursor: "pointer"
  },
  voteQues: {
    padding: theme.spacing(1),
    display: "flex",
    textAlign: "left",
    color: theme.palette.text.primary
  }
});

class ExecResponse extends Component {
  //   const classes = useStyles();
  constructor() {
    super();
    this.state = {
      rows: [],
      executives: [],
      executive: "",
      noevent: false,
      allChecked: true,
      selected: [],
      publishMsg: "",
      date: ""
    };
  }

  componentWillMount() {
    const stitchAuthedClient = this.props.stitchAuthedClient;
    this.getExecutivesFromStitch("{}");
    this.getQuesFromStitch(JSON.stringify({}));
    this.getEventFromStitch(JSON.stringify({}));
  }

  getExecutivesFromStitch = data => {
    const stitchAuthedClient = this.props.stitchAuthedClient;
    stitchAuthedClient
      .callFunction("fetchExecutives", [data])
      .then(x => {
        // console.log("Status is ", x);
        return x;
      })
      .then(resData => {
        this.setState({ executives: resData });
      });
  };

  getEventFromStitch = data => {
    const stitchAuthedClient = this.props.stitchAuthedClient;
    // console.log("stitchAuthedClient ", stitchAuthedClient);
    stitchAuthedClient
      .callFunction("lastClosedEvent", [data])
      .then(x => {
        // console.log("Status is", x);
        return x;
      })
      .then(res => {
        // console.log("RESULT of event fetch: ", res);
        const parseRes = JSON.parse(res);
        const date = new Date(parseRes.date)
        const month = date.getMonth();

        const month_name = function (dt) {
          const mlist = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
          return mlist[dt];
        };

        this.setState({
          month: month_name(month) + " All Hands"
        });
        // console.log("HERE: ", this.state);
      });
  }

  getQuesFromStitch = data => {
    // console.log("data in stitch is: ", data);
    const stitchAuthedClient = this.props.stitchAuthedClient;
    stitchAuthedClient
      .callFunction("lastClosedOpenForResponse", [data])
      .then(x => {
        // console.log("Status is", x);
        // this.setState({rows:x})
        return x;
      })
      .then(resData => {
        if (resData.status !== 422) {
          // console.log("resdata is ", resData);
          let resDataProcessed = [];
          JSON.parse(resData).forEach((x, idx) => {
            let res = createData(
              idx,
              x.question_id,
              x.question,
              x.who,
              x.votes,
              x.status,
              x.response,
              x.responder,
              x.hidden
            );
            resDataProcessed.push(res);
          });
          this.setState({ rows: resDataProcessed });
          // console.log("processed data is ", resDataProcessed);
        } else {
          this.setState({ noevent: true });
        }
      });
  };

  respondToQuestion = (data, id) => {
    // console.log("data in stitch is: ", data);
    const stitchAuthedClient = this.props.stitchAuthedClient;
    if (data.responder[0].eid != "") {
      stitchAuthedClient
        .callFunction("respondToQuestionFunction", [JSON.stringify(data)])
        .then(x => {
          // console.log("Status is", x);
          if (x.status !== 422) {
            this.setState({ msg: "Submitted successfully!" });
            document.getElementById(id).value = "";
          } else {
            this.setState({ msg: "Response couldn't be saved. Enter response & tag executive correctly." });
          }
        });
    } else {
      this.setState({ msg: "Please tag a correct executive responder." })
    }
  };

  handleCheckChange = event => {
    event.persist();
    // console.log("val", event);

    if (event.target.id != "allChecked") {
      if (event.target.checked) {
        if (this.state.allChecked) {
          this.setState({ allChecked: false });
        }
        let newSelected = this.state.selected;
        newSelected.push(event.target.value);
        this.setState({ selected: newSelected });
        // console.log("changed state 1 ", this.state);
      } else {
        let newSelected = this.state.selected;
        newSelected = newSelected.filter(x => x != event.target.value);
        this.setState({ selected: newSelected });
        if (this.state.selected.length === 0) {
          this.setState({ allChecked: true });
        }
        // console.log("changed state 2 ", this.state);
      }
    } else {
      if (event.target.checked) {
        this.setState({ allChecked: true, selected: [] });
        // console.log("changed state 3 ", this.state);
      }
    }
  };

  handleExecChange = (event, idx) => {
    event.persist();
    // console.log("handle exec change ", event);
    // console.log("handle exec change value  ", event.target.value);
    const exec = JSON.parse(event.target.value);

    let newState = this.state;

    newState.rows[idx].execID = exec.eid;
    newState.rows[idx].execName = exec.name;
    newState.rows[idx].execTitle = exec.title;
    const stitchAuthedClient = this.props.stitchAuthedClient;
    this.setState(newState);
    stitchAuthedClient
      .callFunction("tagExecToQues", [
        JSON.stringify({
          question_id: this.state.rows[idx].qid,
          responder: [
            {
              name: exec.name,
              title: exec.title,
              eid: exec.eid
            }
          ]
        })
      ])
      .then(x => {
      });
    // console.log("newstate is ", this.state);
  };

  handleSubmit = (event, id) => {
    // console.log("handleclick ", this.state);
    // console.log(event);

    const arrayCopy = [...this.state.rows];

    const textID = "txt-" + id;
    const respID = "response-" + id;
    const res = document.getElementById(textID);

    const data = {
      question_id: arrayCopy[id].qid,
      response: res.value,
      responder: [
        {
          name: arrayCopy[id].execName,
          title: arrayCopy[id].execTitle,
          eid: arrayCopy[id].execID
        }
      ],
      status: "answered"
    };

    this.setState({ rows: arrayCopy });

    this.respondToQuestion(data, textID);

    document.getElementById(respID).innerHTML = res.value;
    // console.log("ac", arrayCopy);
  };

  handleEdit = (event, id) => {
    const arrayCopy = [...this.state.rows];
    arrayCopy[id].status = "open"
    this.setState({ rows: arrayCopy });
  }

  handleCancelEdit = (event, id) => {
    const arrayCopy = [...this.state.rows];
    arrayCopy[id].status = "answered"
    this.setState({ rows: arrayCopy });
  }

  handleEditChange = (event, id) => {
    const arrayCopy = [...this.state.rows];
    arrayCopy[id].response = event.target.value
    this.setState({ rows: arrayCopy })
  }

  handlePublishResponses = (event, id) => {
    const stitchAuthedClient = this.props.stitchAuthedClient;
    stitchAuthedClient
      .callFunction("markReadyToPublish")
      .then(x => {
        // console.log("Status is", x);
        if (x.status !== 422) {
          this.setState({ publishMsg: "Published successfully! See Last All Hands Report." });
        } else {
          this.setState({ publishMsg: "Couldn't publish. Please try again." });
        }
      });
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <div><h3>{this.state.month}</h3></div>
        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox
                checked={this.state.allChecked}
                onChange={event => this.handleCheckChange(event)}
                value="all"
                id="allChecked"
              />
            }
            label="All"
          />
          {this.state.executives.map(row => (
            <FormControlLabel
              control={
                <Checkbox
                  onChange={event => this.handleCheckChange(event)}
                  value={row.eid}
                  id={row.eid}
                />
              }
              label={row.name}
            />
          ))}
        </FormGroup>
        <div>
          {this.state.rows.map(
            row =>( !row.hidden &&
              (this.state.selected.includes(String(row.execID)) ||
                this.state.selected.length === 0) && 
                (
                <ExpansionPanel className={classes.root} key={row.qid}>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <br />
                    <Grid container spacing={2} xs={4}>
                      <Grid item xs>
                        <Typography>
                          {row.status !== "open" && (
                            <button
                              type="button"
                              style={{ fontSize: 14, borderRadius: 7 }}
                              disabled
                            >
                              <b style={{ color: "green" }}>{row.execName}</b>
                            </button>
                          )}
                          {row.status === "open" && (
                            <button
                              type="button"
                              style={{ fontSize: 14, borderRadius: 7 }}
                              disabled
                            >
                              <b style={{ color: "red" }}>{row.execName}</b>
                            </button>
                          )}
                        </Typography>
                      </Grid>
                      <Grid item xs>
                        <div className={classes.voteQues}>
                          <Badge badgeContent={row.voteCount} color="primary">
                            <ThumbUp />
                          </Badge>
                        </div>
                      </Grid>
                    </Grid>

                    <Typography className={classes.root}>
                      <span style={{ color: "blue" }}>[Q-{row.qid}] </span>
                      {row.question}
                    </Typography>
                  </ExpansionPanelSummary>

                  <ExpansionPanelDetails className={classes.expansionPanel}>
                    {
                      row.status !== "open" &&
                      <div>{row.response}</div>
                    }
                    {row.status === "open" && (
                      <Fragment>
                        <div id={"response-" + row.idx} />
                        <TextField
                          id={"txt-" + row.idx}
                          label="Executive Says..."
                          multiline
                          rows="4"
                          margin="normal"
                          fullWidth
                          inputProps={{ maxLength: 600 }}
                          className={classes.text}
                          display="block"
                          value={row.response}
                          onChange={(event, idx) => this.handleEditChange(event, row.idx)}
                        />
                        <br />
                        <FormControl className={classes.formControl}>
                          <InputLabel htmlFor="select-multiple-native">
                            Executive
                          </InputLabel>
                          <Select
                            native
                            onChange={(event, idx) =>
                              this.handleExecChange(event, row.idx)
                            }
                            inputProps={{
                              id: `"select-exec-${row.qid}`
                            }}
                          >
                            <option value="" />
                            {this.state.executives.map(exec => (
                              <option
                                key={exec.eid}
                                value={JSON.stringify(exec)}
                              >
                                {exec.name}
                              </option>
                            ))}
                          </Select>
                        </FormControl>
                        <br />
                        <Button
                          id={row.idx}
                          onClick={event => this.handleSubmit(event, row.idx)}
                          variant="contained"
                          color="primary"
                        >
                          SUBMIT
                        </Button>
                        <br />
                        <div>{this.state.msg}</div>
                      </Fragment>
                    )}
                    <br />
                    {row.status !== "open" && (
                      <Button
                        id={row.idx}
                        onClick={event => this.handleEdit(event, row.idx)}
                        variant="contained"
                        color="primary"
                      >
                        EDIT RESPONSE
                    </Button>
                    )}

                    {row.status === "open" && row.response.length > 0 && (
                      <Button
                        id={row.idx}
                        onClick={event => this.handleCancelEdit(event, row.idx)}
                        variant="contained"
                        color="secondary"
                      >
                        CANCEL
                    </Button>
                    )}
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              )
          ))}
          <br />
          <div>
            <Grid container spacing={2} xs={4}>
              <Grid item xs>
                <CsvDownload
                  data={this.state.rows}
                  class={classes.button}
                  filename="All_HANDS.csv"
                >
                  DOWNLOAD CSV
            </CsvDownload>
              </Grid>
              <br />
              <Grid item xs>
                <Button
                  id="hey"
                  onClick={event => window.confirm("Are you sure you want to publish the responses ?") && this.handlePublishResponses(event)}
                  variant="contained"
                  color="primary"
                >
                  PUBLISH RESPONSES
            </Button>
              </Grid>
            </Grid>
            {this.state.publishMsg}
          </div>

        </div>
      </div>
    );
  }
}

ExecResponse.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ExecResponse);

import React, { Component } from "react";
import { fade } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from "clsx";
import Grid from "@material-ui/core/Grid";

const styles = theme => ({
  smallHeight: {
    paddingTop: 10,
    paddingRight: 10,
    paddingBottom: 10,
    paddingLeft: 10
  },
  grow: {
    flexGrow: 1
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block"
    }
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25)
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto"
    }
  },
  searchIcon: {
    width: theme.spacing(7),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  inputRoot: {
    color: "inherit"
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: 200
    }
  }
});

function createData(qid, question, who, response, responder, when, votes) {
  if (!(votes instanceof Array)) {
    votes = [];
  }

  if (!(responder instanceof Array)) {
    responder = [{ name: "", title: "" }];
  }

  const voteCount = votes.length;
  const name = responder[0]["name"];
  // console.log(' responder[0]["name"]', responder[0]["name"]);
  return { qid, question, who, response, name, when, voteCount };
}

class SearchStuff extends Component {
  constructor() {
    super();
    this.state = {
      question: "",
      rows: [],
      isEmpty: false
    };
  }

  handleQuestionChange = event => {
    this.setState({ question: event.target.value });
  };

  handleSubmit = event => {
    // event.PreventDefault();
    let question = this.state.question.trim();

    this.getFromStitch(JSON.stringify({ ftssearch: question }));
  };

  getFromStitch = data => {
    // console.log("data in stitch is: ", data);
    const stitchAuthedClient = this.props.stitchAuthedClient;
    // console.log("stitchAuthedClient ", stitchAuthedClient);
    stitchAuthedClient
      .callFunction("ftsSearchFunction", [data])
      .then(x => {
        // console.log("Status is", x);
        // this.setState({rows:x})
        return x;
      })
      .then(resData => {
        this.setState({ isEmpty: false });
        // console.log("resdata is ", resData);
        let resDataProcessed = [];
        JSON.parse(resData).forEach(x => {
          let res = createData(
            x.question_id,
            x.question,
            x.username,
            x.response,
            x.responder,
            x.posted_on,
            x.votes
          );
          resDataProcessed.push(res);
        });
        this.setState({ rows: resDataProcessed });
        if (resDataProcessed.length === 0) {
          this.setState({ isEmpty: true });
        }
        // console.log("resdataprocessed ", resDataProcessed);
      });
  };

  dateManipulator(d) {
    var dateObj = new Date(d);
    var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    return months[dateObj.getMonth()] + " " + dateObj.getFullYear()
  }

  render() {
    const { classes } = this.props;
    const smallHeightPaper = clsx(classes.paper, classes.smallHeight);

    return (
      <Container>
        <Grid item xs style={{ width: '100%' }}>
          <Paper className={smallHeightPaper}>
            <b>This page is still in beta. Enter a keyword in the search bar below to explore the All Hands Database</b>
          </Paper>
          <br />
        </Grid>
        <Paper>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Search…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput
              }}
              inputProps={{ "aria-label": "Search" }}
              onChange={this.handleQuestionChange}
              value={this.state.question}
              fullWidth="true"
              display="block"
              onKeyPress={(ev) => {
                if (ev.key === "Enter") {
                  this.handleSubmit()
                }
              }
              }
            />
          </div>
        </Paper>
        <br />

        {this.state.isEmpty && <p>No Matches Found</p>}

        {this.state.rows.map(row => (
          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <div style={{ padding: "2px" }}>
                <b>{row.question}</b>
                &nbsp;
                <i>
                  Posted On: {this.dateManipulator(row.when)}
                  &nbsp;
              Posted By: {row.who}
                  &nbsp;
              Responded By: {row.name}
                  &nbsp;
              Votes: {row.voteCount}
                </i>
              </div>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <div style={{ color: "blue" }}><i>{row.response}</i></div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        ))}

      </Container>
    );
  }
}

SearchStuff.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(SearchStuff);

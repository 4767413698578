import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import ThumbUp from "@material-ui/icons/ThumbUp";
import Button from "@material-ui/core/Button";
import Badge from "@material-ui/core/Badge";
import IconButton from '@material-ui/core/IconButton';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#bdbdbd',
      main: '#388e3c'
    },
    secondary: {
      light: '#bdbdbd',
      main: '#757575'
    },
    error: {
      light: '#bdbdbd',
      main: '#1a237e'
    }
  }
});





let global_username = "";

const StyledTable = withStyles(theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto",
  },
  table: {
    minWidth: 700
  }
}))(Table);

const StyledPaper = withStyles(theme => ({
  root: {
    width: "100%",
    overflowX: "auto"
  },
  table: {
    minWidth: 700
  }
}))(Paper);

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  body: {
    fontSize: 16,
    fontFamily: "sans-serif",
    fontWeight: 600,
    overflow: "auto",
  }
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default
    }
  }
}))(TableRow);



function createData(qid, question, who, voteCount, voted,posted_on) {
  let color = (voted !== -1) ? "primary" : "secondary";
  return { qid, question, who, color, voteCount,posted_on };
}

export default class extends Component {
  //   const classes = useStyles();

  constructor() {
    super();
    this.state = {
      rows: [],
      noevent: false,
      refreshNow: false
    };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick = event => {
    // console.log("handleclick ", this.state);
    // console.log("current target", event.currentTarget.id);

    const arrayCopy = [...this.state.rows];
    arrayCopy.forEach(row => {
      // console.log("current target", event.currentTarget.id);
      // console.log("current question", row.qid);
      if (String(row.qid) === event.currentTarget.id) {
        // qid is number vs string
        // console.log("event.currentTarget.id ", event.currentTarget.id);
        const currentColor = row.color;
        row.color = currentColor === "secondary" ? "primary" : "secondary";
        const action = currentColor === "secondary" ? "push" : "pop";
        const voteData = {
          question_id: parseInt(row.qid),
          location: this.props.userLocation,
          action: action
        };
        // console.log("action: ", voteData);
        this.voteOnQuestion(JSON.stringify(voteData));

        if(action=='push')
          row.voteCount = row.voteCount +1
        if(action=='pop')
          row.voteCount = row.voteCount-1
      
      }
    });

    // console.log("ac", arrayCopy);

    this.setState({ rows: arrayCopy });
  };

  componentDidMount() {
    // console.log(this.props)
    this.getFromStitch(JSON.stringify({}));
  }

  componentDidUpdate(prevProps) {
    // console.log("cdu ",this.props.refresh, "prevProps", prevProps.refresh)
    if (this.props.refresh === "refresh" && this.props.refresh !== prevProps.refresh) {
      this.getFromStitch(JSON.stringify({}));
    }
  }

  getFromStitch = data => {
    const stitchAuthedClient = this.props.stitchAuthedClient;
    stitchAuthedClient
      .callFunction("fetchQuestionsOpenEventFunctionLimited", [data])
      .then(x => {
        // console.log("Status is", x);
        // this.setState({rows:x})
        return x;
      })
      .then(resData => {
        if (resData.status !== 422) {
          // console.log("resdata from getfromstitch is ", resData);
          global_username = this.props.user;
          let resDataProcessed = [];
          JSON.parse(resData).forEach(x => {
            let res = createData(
              x.question_id,
              x.question,
              x.username,
              x.voteCount,
              x.voted,
              x.posted_on
            );
            resDataProcessed.push(res);
          });
          this.setState({ rows: resDataProcessed });
           //console.log("resdataprocessed ", resDataProcessed);
        } else {
          this.setState({ noevent: true })
        }
      });
  };

  voteOnQuestion = data => {
    // console.log("vote on question data in stitch is: ", data);
    const stitchAuthedClient = this.props.stitchAuthedClient;
    stitchAuthedClient.callFunction("modifyVoteFunction", [data]).then(x => {
      // console.log("Status is", x);
    });
  };



  render() {

    const { classes } = this.props;

    return (
      <ThemeProvider theme={theme}>
        <StyledPaper>
          <p align="center">
            <b>Vote for the questions that matter to you!</b>
          </p>
          <StyledTable>
            <TableHead>
              <TableRow>
                <StyledTableCell style={{ "font-size": 16 }}><b>Question</b></StyledTableCell>
                <StyledTableCell style={{ "font-size": 16 }} align="right"><b>Who Asked</b></StyledTableCell>
                <StyledTableCell style={{ "font-size": 16 }} align="right"><b>Upvote</b></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.rows.map(row => (
                <StyledTableRow key={row.qid}>
                  <StyledTableCell component="th" scope="row" style={{
                    whiteSpace: "pre-wrap",
                    wordWrap: "break-word",
                    maxWidth: "730px"
                  }}>
                    {row.question }
                    <br />
                     <i style={{color:"#A29F9E", "font-size":"12px"}}>{"\nPosted On : "+ new Date(Date.parse(row.posted_on))}</i>
                  </StyledTableCell>
                  <StyledTableCell align="right">{row.who}</StyledTableCell>
                  <StyledTableCell align="right">
                    <IconButton id={row.qid}
                      color={row.color}
                      onClick={this.handleClick} >
                      <Badge badgeContent={row.voteCount} color="error">
                        <ThumbUp />
                      </Badge>
                    </IconButton>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </StyledTable>
          {this.state.noevent && <p align="center"><b>Looks like there is no open event</b></p>}
        </StyledPaper>
      </ThemeProvider>
    );
  }
}


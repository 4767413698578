import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ArchiveIcon from '@material-ui/icons/Archive';
import PeopleIcon from '@material-ui/icons/People';
import AssignmentIcon from '@material-ui/icons/Assignment';
import Link from '@material-ui/core/Link';
import SearchIcon from "@material-ui/icons/Search";
import VoteIcon from "@material-ui/icons/HowToVote"
import Typography from "@material-ui/core/Typography";

function SingleListItem(props) {
  const Icon = props.icon
  const styles = {
      text:{
        fontWeight: "bold"
      },
      button: {
      }
  }

  if (props.activeUrl == props.url) {
    styles.button.backgroundColor = "#D5F5E3"
  }

  return (
    <Link href={props.url} passHref>
      <ListItem button style={styles.button}>
        <ListItemIcon>
          <Icon />
        </ListItemIcon>
        <ListItemText primary={<Typography type="body2" style={styles.text}>{props.title}</Typography>} />
      </ListItem>
    </Link>
  )
}

export default function MainListItems(props) {
  return (
    <div>
      <SingleListItem url="/" title="Home" icon={DashboardIcon} activeUrl={props.activeUrl}/>
      {/*<SingleListItem url="/voting" title="Vote Here" icon={VoteIcon} activeUrl={props.activeUrl}/>*/}
      {props.moderator&&<SingleListItem url="/createevent" title="Manage Event" icon={ArchiveIcon} activeUrl={props.activeUrl}/>}
      {props.moderator&&<SingleListItem url="/execresponse" title="Executive Says" icon={PeopleIcon} activeUrl={props.activeUrl}/>}
      <SingleListItem url="/searchstuff" title="Search Knowledge" icon={SearchIcon} activeUrl={props.activeUrl}/>
      <SingleListItem url="/reports" title="All Reports" icon={AssignmentIcon} activeUrl={props.activeUrl}/>
    </div>
  )
}
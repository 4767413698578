import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import ClearIcon from '@material-ui/icons/Clear';
import Grid from "@material-ui/core/Grid";
import CsvDownload from "react-json-to-csv";
import PropTypes from "prop-types";

import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import QuestionFormClosedEvent from './QuestionFormClosedEvent';

const styles = theme => ({
  button: {
    backgroundColor: "#4CAF50" /* Green */,
    border: "none",
    color: "white",
    padding: "15px 32px",
    textAlign: "center",
    textDecoration: "none",
    display: "inline-block",
    fontSize: "16px",
    cursor: "pointer"
  }
});

const StyledTable = withStyles(theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto",
  },
  table: {
    minWidth: 700
  }
}))(Table);

const StyledPaper = withStyles(theme => ({
  root: {
    width: "100%",
    overflowX: "auto"
  },
  table: {
    minWidth: 700
  }
}))(Paper);

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  body: {
    fontSize: 16,
    fontFamily: "sans-serif",
    fontWeight: 600,
    overflow: "auto",
  }
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default
    }
  }
}))(TableRow);

function getNumbers(data) {
  return Object.keys(data).map( (key) => {
      return <div style={{display: "inline"}}>{key} : {data[key]}, </div>;
  });
}

function voteDistribution(votes){
  let res = {}
  let votesLen = votes.length
  for(var i=0;i<votesLen;i++){
    if(votes[i].loc in res){
      res[votes[i].loc] = res[votes[i].loc] + 1
    } else {
      res[votes[i].loc] = 1
    }
  }
  return res
}

function createData(qid, question, who, hidden, votes) {
  var color
  if (hidden) {
    color = "red"
  } else {
    color = "grey"
  }
  if (!(votes instanceof Array)) {
    votes = [];
  }
  const voteCount = votes.length;

  const votePattern = voteDistribution(votes)
  return { qid, question, who, color, voteCount, votePattern };
}

class AdminPanel extends Component {
  //   const classes = useStyles();

  constructor() {
    super();
    this.state = {
      rows: [],
      noevent: false,
      sortOrder:false,
      boolvalue :false,
      text1:"Votes",
      text2:"Expand",
      lastEventID: -1
    };
    this.handleClick = this.handleClick.bind(this);
    this.Changesortorder = this.Changesortorder.bind(this);
    this.HideorExpand = this.HideorExpand.bind(this);
    this.Button1textchange = this.Button1textchange.bind(this);
    this.Button2textchange = this.Button2textchange.bind(this);
    this.WrapperFunctionForButton1 = this.WrapperFunctionForButton1.bind(this);
    this.WrapperFunctionForButton2 = this.WrapperFunctionForButton2.bind(this);
  }

  handleClick = event => {
    // console.log("handleclick ", this.state);
    // console.log("current target", event.currentTarget.id);

    const arrayCopy = [...this.state.rows];
    arrayCopy.forEach(row => {
      // console.log("current target", event.currentTarget.id);
      // console.log("current question", row.qid);
      if (String(row.qid) === event.currentTarget.id) {
        // qid is number vs string
        // console.log("event.currentTarget.id ", event.currentTarget.id);
        const currentColor = row.color;
        row.color = currentColor === "red" ? "grey" : "red";
        const action = currentColor === "red" ? "unhide" : "hide";
        const voteData = {
          question_id: parseInt(row.qid),
          action: action
        };
        // console.log("action: ", voteData);
        this.visibilityOfQuestion(JSON.stringify(voteData)); 

      }
    });

    // console.log("ac", arrayCopy);

    this.setState({ rows: arrayCopy });
  };



componentDidMount() {
  // console.log("mounted")
  var data;
  const stitchAuthedClient = this.props.stitchAuthedClient;
  stitchAuthedClient.callFunction("fetchQuestionsOpenEventFunction", [data]).then(x => {
        if(x.status == 422){
          // console.log("stitchfunction")
          this.setState({noevent: true})
        }
        return x;
      })
 
 // store the state of the parent so that I can access it insdie the timeout function

 var stateholder = this

setTimeout( function(){
  // console.log("function invoke")
    if(stateholder.state.noevent == true)
     stateholder.callClosedEvent()
   if(stateholder.state.noevent == false)
    stateholder.callOpenEvent()
  }, 2000 );

  }



Button1textchange(){
    if(this.state.text1 == "Votes")
      this.setState({ text1: "Date" });
    else if(this.state.text1 == "Date")
      this.setState({ text1: "Votes" });
    
  }
  Button2textchange(){
    if(this.state.text2 == "Expand")
      this.setState({ text2: "Hide" });
    else if(this.state.text2 == "Hide")
      this.setState({ text2: "Expand" });
  }

  WrapperFunctionForButton1() {
    this.Changesortorder();
    this.Button1textchange();
  }
  WrapperFunctionForButton2() {
    this.HideorExpand();
    this.Button2textchange();
  }


  HideorExpand(){
    if (this.state.boolvalue==false) 
        this.setState({ boolvalue: true });
    if (this.state.boolvalue == true) 
        this.setState({ boolvalue: false });

  }

  Changesortorder() {
    if(this.state.sortOrder==false){
      this.setState({ sortOrder: true });
    }
    else{
      this.setState({ sortOrder: false });
    }
  }


  callOpenEvent(){
    this.getFromStitch(JSON.stringify({}));
    setInterval(() => {
      this.getFromStitch(JSON.stringify({}));
    }, 10000)
  }

  callClosedEvent(){
    this.getFromStitchWhenClosed(JSON.stringify({}));
    this.getEventIDWhenClosed();
    setInterval(() => {
      this.getFromStitchWhenClosed(JSON.stringify({}));
    }, 10000)

  }
  

  getFromStitch = data => {
    const stitchAuthedClient = this.props.stitchAuthedClient;
    stitchAuthedClient
      .callFunction("fetchQuestionsOpenEventFunction", [data])
      .then(x => {
        // console.log("Status is", x);
        // this.setState({rows:x})
        return x;
      })
      .then(resData => {
        if (resData.status !== 422) {
          // console.log("inside if ")
          let resDataProcessed = [];
          JSON.parse(resData).forEach(x => {
            let res = createData(
              x.question_id,
              x.question,
              x.username,
              x.hidden,
              x.votes
            );
            resDataProcessed.push(res);
          });
          // console.log(resDataProcessed)
          let sortedData = resDataProcessed.map(a => Object.assign({}, a));

          sortedData.sort(function (a, b) {return b.voteCount - a.voteCount; });    
          
          if(this.state.sortOrder==true)
          this.setState({ rows: sortedData });
        else
          this.setState({ rows: resDataProcessed });
          
        } else {
          this.setState({noevent: true})
        }
        
      })
  };

  getEventIDWhenClosed = () => {
    const stitchAuthedClient = this.props.stitchAuthedClient;
    stitchAuthedClient
      .callFunction("lastClosedOpenForResponseEventID", [])
      .then(x => {
        let res = JSON.parse(x)
        this.setState({lastEventID: res})
      })
  }

  getFromStitchWhenClosed = data => {
    const stitchAuthedClient = this.props.stitchAuthedClient;
    stitchAuthedClient
      .callFunction("lastClosedOpenForResponse", [data])
      .then(x => {
        // console.log("Status is", x);
        // this.setState({rows:x})
        return x;
      })
      .then(resData => {
        if (resData.status !== 422) {
          let resDataProcessed = [];
          JSON.parse(resData).forEach(x => {
            let res = createData(
              x.question_id,
              x.question,
              x.username,
              x.hidden,
              x.votes
            );
            resDataProcessed.push(res);
          });
          let unsortedData = resDataProcessed.map(a => Object.assign({}, a));

          unsortedData.sort(function (a, b) {return b.qid - a.qid; });    
          
          if(this.state.sortOrder==true)
          this.setState({ rows: resDataProcessed });
        else
          this.setState({ rows: unsortedData });
          
        } else {
          this.setState({noevent: true})

        }
        
      })
  };

  visibilityOfQuestion = data => {
    //  console.log("vote on question data in stitch is: ", data);
    const stitchAuthedClient = this.props.stitchAuthedClient;
    stitchAuthedClient.callFunction("modifyHidden", [data]).then(x => {
      //  console.log("Status is", x);
    });
  };

  render() {
    const { classes } = this.props;

    return (
      
  <div>
    <div>
      {this.state.lastEventID !== -1 && 
      <QuestionFormClosedEvent 
      user={this.props.user}
      event_id={this.state.lastEventID}
      stitchAuthedClient={this.props.stitchAuthedClient}
      userLocation={this.props.userLocation}/>}

      <Grid container spacing={3}>
        <Grid item xs={6} style={{"font-weight": "bold", "align-items": "center"}}> 
          <p> Sort By : </p>
        </Grid>
        <Grid item xs={2} style={{"font-weight": "bold", "align-items": "center"}}> 
          <Button variant="contained"
                    onClick={this.WrapperFunctionForButton1.bind()}
                    style={{color:'green'}}
                  >{this.state.text1}</Button>
        </Grid>
        <Grid item xs={2} style={{"font-weight": "bold", "align-items": "center"}}> 
          <Button variant="contained"
                    onClick={this.WrapperFunctionForButton2.bind()}
                    style={{color:'green'}}
                  >{this.state.text2}</Button>
        </Grid>
        </Grid>


          <StyledTable>
            <TableHead>
            <TableRow>
               
              <StyledTableCell style={{"font-size": 16, "padding-right": "80px"}}><b>Question</b></StyledTableCell>
              <StyledTableCell style={{"font-size": 16 , "padding-left": "150px"}} align="right"><b>Who Asked</b></StyledTableCell>
              <StyledTableCell style={{"font-size": 16 , "padding-left": "10px"}} align="right"><b>Votes</b></StyledTableCell>
              <StyledTableCell style={{"font-size": 16 }} align="right"><b>Hide</b></StyledTableCell>
              
            </TableRow>
          </TableHead>
          </StyledTable>


    {this.state.rows.map(row => (


      <ExpansionPanel expanded={this.state.boolvalue}
      defaultExpanded={this.state.boolvalue} className={classes.root} key={row.qid}>

              <ExpansionPanelSummary aria-controls="panel1a-content" id="panel1a-header" style={{backgroundColor: '#D3D3D3',
                    borderBottom: '1px solid rgba(0, 0, 0, .125)'}}>
                    <br />


        <Grid container spacing={4}>
        <Grid item xs={7} style={{"font-weight": "bold", "align-items": "center"}}> 
          {row.question}
        </Grid>
      
        <Grid item xs={3} style={{"font-weight": "bold"}}>
          {row.who}
        </Grid>
        
        <Grid item xs style={{"font-weight": "bold"}}>
          {row.voteCount}
        </Grid>

  
        <Grid item xs={1} style={{"font-weight": "bold"}} onClick={event => event.stopPropagation()}>
         <Button variant="contained" id={row.qid} onClick={this.handleClick} style={{"background-color": row.color}}>
              <ClearIcon />
         </Button>
        </Grid>
        </Grid>
        </ExpansionPanelSummary>


        <ExpansionPanelDetails>
          <Grid container spacing={1}>
          <Grid item xs style={{"font-weight": "bold"}}> 
            {getNumbers(row.votePattern)}
          </Grid>
          </Grid>

        </ExpansionPanelDetails>
      </ExpansionPanel>
                  
))}
    </div>

          
        <Grid container spacing={2} xs={4}>
          <Grid item xs>
            <CsvDownload
              data={this.state.rows}
              class={classes.button}
              filename="All_HANDS.csv"
            >
              DOWNLOAD CSV
            </CsvDownload>
          </Grid>
        </Grid>
      </div>
    );
  }
}

AdminPanel.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(AdminPanel);
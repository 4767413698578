import React, { Component } from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import QuestionForm from "./QuestionForm";
import clsx from "clsx";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import UpvoteQuestion from "./UpvoteQuestion"
import ClosedAllHands from './ClosedAllHands';
import AwesomeComponent from './Loading';
import Popup from "reactjs-popup";
import Button from "@material-ui/core/Button";


const StyledButton = withStyles({
  root: {
    borderRadius: 3,
    border: 0,
    color: 'white',
    height: 48,
    padding: '0 30px',
  },
  label: {
    textTransform: 'capitalize',
  },
})(Button);

const styles = theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    justify: "center",
    alignItems: "center"
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    display: "block"
  },
  fixedHeight: {
    height: "flex"
  },
  smallHeight: {
    paddingTop: 20,
    paddingRight: 10,
    paddingBottom: 10,
    paddingLeft: 10
  }
});

class UpcomingAllHands extends Component {
  constructor() {
    super();
    this.state = { date: "", details: "", event_id:-1, noevent: false, refresh: "" };
  }

  componentWillMount() {
    this.getEventFromStitch(JSON.stringify({ status: "open" }));
  }

  handleRefresh = data => {
    this.setState({ refresh: data })
  }

  getEventFromStitch = data => {
    const stitchAuthedClient = this.props.stitchAuthedClient;
    // console.log("stitchAuthedClient ", stitchAuthedClient);
    stitchAuthedClient
      .callFunction("fetchEventsFunction", [data])
      .then(x => {
        // console.log("Status is", x);
        return x;
      })
      .then(res => {
        // console.log("RESULT of event fetch: ", res, res[0]);
        const parseRes = JSON.parse(res);
        if (parseRes.length !== 0) {
          // console.log(
          //   "Parse RESULT of event fetch: ",
          //   parseRes,
          //   Date.parse(parseRes[0].date)
          // );
          this.setState({
            date: parseRes[0].date,
            details: parseRes[0].details,
            event_id: parseRes[0].event_id
          });
        } else {
          this.setState({ noevent: true });
        }
        // console.log("HERE: ", this.state);
      });
  };

  render() {
    const { classes } = this.props;
    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
    const smallHeightPaper = clsx(classes.paper, classes.smallHeight);
    const eventDate = new Date(Date.parse(this.state.date));



    if (!this.state.event_id===-1 && this.state.noevent===false){
      return <div><AwesomeComponent/></div>
    }

    if (this.state.date==="" && this.state.noevent===false){
      return <div><AwesomeComponent/></div>
    }

    return (
      <div>
      <Container maxWidth="xl" className={classes.container}>
        <Grid container spacing={1} direction="column" alignItems="center">
          {/* Upcoming All Hands */}
          <Grid item xs style={{ width: '100%' }}>
            <Paper className={smallHeightPaper} >
              {!this.state.noevent && (
                <div style={{ width: '100%', left: '100px' }}>
                  <h3>Upcoming All Hands on {eventDate.toString()} </h3>
                </div>
              )}
              {this.state.noevent && <b>Question Submission Is CLOSED. See #all-hands-questions Slack Channel.</b>}
            </Paper>
          </Grid>
         
          {/*Ask Question */}
          <Grid item xs style={{ width: '100%' }}>
            <Paper className={fixedHeightPaper}>
              {!this.state.noevent && (
                <QuestionForm
                  sendRefreshSignal={this.handleRefresh}
                  user={this.props.user}
                  event_id={this.state.event_id}
                  stitchAuthedClient={this.props.stitchAuthedClient}
                  userLocation={this.props.userLocation}
                />
              ) }
            </Paper>
          </Grid>
          <br />
          <Grid item xs style={{ width: '100%' }}>
            {!this.state.noevent && <UpvoteQuestion refresh={this.state.refresh} user={this.props.user} userLocation={this.props.userLocation} stitchAuthedClient={this.props.stitchAuthedClient} />}
            {this.state.noevent && <ClosedAllHands refresh={this.state.refresh} user={this.props.user} stitchAuthedClient={this.props.stitchAuthedClient} />}
          </Grid>
        </Grid>
      </Container>

      {localStorage.getItem("event_id") != this.state.event_id && 
    <Popup defaultOpen={true} closeOnDocumentClick={false} disabled={false} repositionOnResize={true} contentStyle={{ "border-color":"#12924F","background-color":"#FAFAFA", "text-align":"justify",
     width:"screen.width", "margin-left":"250px","margin-top":"75px","margin-right":"25px", height:"80%", padding: "30px", border: "solid", "border-width": "2px", "font-family":"Helvetica", "font-size":"15px",
     
     }} position="right center"> 
    {close => (
      <div className="modal"> 
        <h3>Rules of Engagement for the All Hands Q&A Portal</h3>
        <hr style={{"border": "4px solid #12924f", "border-radius": "5px"}} />
        
        <Container style={{"font-size":"15px"}}>
       
          MongoDB All Hands Meetings are held regularly to share company news, address key topics, and answer questions.
    
            <br></br><br></br><b>If you would like to ask a question before the All Hands, we encourage you to consider these points before you do: 
            </b>

            <ul>
                <li>Is it relevant to our business?</li>
                <li style={{"margin-top":"6px"}}>Is it better asked of your manager or HR first? </li>
                <li style={{"margin-top":"6px"}}>Will it be perceived as an attack or make an individual or a group at MongoDB feel unwelcome? </li>
                <li style={{"margin-top":"6px"}}> Will the answer be useful beyond a specific team or location? If not, consider asking your site leader, manager or local People Team contact.</li>
            </ul>
            <b>Other things to note: </b>
              
            <ul>
                <li style={{"margin-top":"6px"}}> Questions are limited to 500 characters to ensure that we can get to as many questions as possible.</li>
                <li style={{"margin-top":"6px"}}> The most upvoted questions will be answered first, and we will try to get to as many as possible during each All Hands.</li>
                <li style={{"margin-top":"6px"}}> Questions that are not answered live will be answered in writing after the meeting. </li>
                <li style={{"margin-top":"6px"}}> Questions are final once submitted and cannot be edited. If you’d like to delete your question, please reach out to Internal Comms directly.</li>
            </ul>

            If you have questions for the upcoming All Hands, submit them here in advance and upvote your favorites.
            <br></br>
            <br></br>
            If you have any questions about the Q&A portal, please email <a href="mailto:internalcomms@mongodb.com">internalcomms@mongodb.com</a>.
           
            </Container>
        <div className="actions" style={{"text-align":"center", }}>
          <br /><br />
          <StyledButton className="button" onClick={() => { close(); }} variant="contained" style={{float: 'center'}}>
           <div style={{color:"#12924F", "font-size":"14px"}}><b>I got it!</b></div> 
          </StyledButton>
        </div>
      </div>
    )}
  </Popup>
  }
  {localStorage.setItem("event_id",this.state.event_id)} 

  </div>
    );
  }
}

UpcomingAllHands.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(UpcomingAllHands);
